"use client";
import { useUserData } from "../../hooks/useUserData";

export function AuthConditional({
  loggedIn = null,
  loggedOut = null,
  loading = null,
}) {
  const { data: user, isLoading } = useUserData();
  if (!loggedIn && !loggedOut) {
    console.error(
      "AuthConditional requires either a loggedIn or loggedOut prop",
    );
    return null;
  }

  if (isLoading) return loading || null;

  return user?.isLoggedIn ? loggedIn : loggedOut;
}
